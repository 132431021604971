<template>
  <div class="main">
    <!-- 轮播 -->
    <div class="swipper">
      <el-carousel
        :interval="500000"
        height="50em"
        @change="changeSwipper"
        indicator-position="none"
        arrow="always"
      >
        <el-carousel-item :class="e.class" v-for="(e, i) in swipers" :key="e.class + i">
          <!-- <loginBackGround class="bgImage" v-if="e.type == 1" :key="e.class + i" /> -->
          <div class="container">
            <p class="title">{{ e.name }}</p>
            <p class="text">
              {{ e.text1 }}
            </p>
            <p class="text">{{ e.text2 }}</p>
            <div class="btns">
              <p class="more" @click="toPage({ index: '4', path: '/home/aboutLink' })">
                了解更多
              </p>
              <p class="consult" @click="toPage({ index: '5', path: '/home/contactUs' })">
                立即咨询
              </p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 内容一 -->

    <div class="content1 container">
      <show :styles="{ top: '18em', left: '-10em' }" :moveData="{ left: '1em' }">
        <div class="info_text">
          <p class="title">公用事业物联网端到端解决方案</p>
          <p>以LinkIoT物联网平台为核心，建立PaaS+SaaS+边缘硬件的产品</p>
          <p>体系，提供端到端（设备端到应用端）的完整物联网解决方案。</p>
        </div>
      </show>
      <show :styles="{ top: '28em', left: '-10em' }" :moveData="{ left: '1em' }">
        <p class="btn btn_style" @click="toPage({ path: '/iot', index: '21' })">
          了解详情
        </p>
      </show>
      <show :styles="{ top: '12em', left: '50em' }" :moveData="{ left: '32em' }">
        <img src="../../assets/images/bg_img/hxcp.png" alt=""
      /></show>
    </div>
    <!-- 内容二 -->
    <div class="content2">
      <div class="container">
        <show :styles="{ top: '15em', left: '50em' }" :moveData="{ left: '45em' }">
          <div class="info_text move2">
            <p class="title">智慧水务</p>
            <p>面向水务监管部门和水务企业，提供从水源地监测、城市供排水管</p>
            <p>理、污水处理到河湖坝堰治理的全流程、一体化智慧水务解决方案。</p>
          </div>
        </show>
        <show :styles="{ top: '26em', left: '50em' }" :moveData="{ left: '45em' }">
          <p
            class="btn btn_style"
            @click="toPage({ path: '/home/smartWater', index: '31' })"
          >
            了解详情
          </p>
        </show>
      </div>
    </div>
    <!-- 内容三 -->
    <div class="content3">
      <div class="container">
        <show :styles="{ top: '15em', left: '-10em' }" :moveData="{ left: '1em' }">
          <div class="info_text move3">
            <p class="title">智慧燃气</p>
            <p>面向城市燃气公司、燃气输配企业，提供基于燃气工业物联网的数</p>
            <p>字三维场站、燃气抢维调度、入户安检、泄漏监测等解决方案。</p>
          </div>
        </show>
        <show :styles="{ top: '26em', left: '-10em' }" :moveData="{ left: '1em' }">
          <p class="btn btn_style" @click="toPage({ path: '/home/gas', index: '32' })">
            了解详情
          </p></show
        >
      </div>
    </div>
    <!-- 内容四 -->
    <div class="content4">
      <div class="container">
        <show :styles="{ top: '15em', left: '50em' }" :moveData="{ left: '45em' }">
          <div class="info_text move4">
            <p class="title">智慧电力新能源</p>
            <p>面向发电、输配电、售电等能源企业，提供从智能运检、智能电厂、</p>
            <p>智能终端、配网在线监测、无源无线测温等智慧电力解决方案。</p>
          </div>
        </show>
        <show :styles="{ top: '26em', left: '50em' }" :moveData="{ left: '45em' }">
          <p
            class="btn btn_style"
            @click="toPage({ path: '/home/newSource', index: '33' })"
          >
            了解详情
          </p></show
        >
      </div>
    </div>
    <!-- 内容五 -->
    <div class="content5">
      <div class="container">
        <show :styles="{ top: '4.5em', left: '50em' }" :moveData="{ left: '1em' }">
          <p class="left">连接改变生活 • LINK FOR GOOD</p>
        </show>
        <show :styles="{ top: '3.5em', left: '70em' }" :moveData="{ left: '60em' }">
          <p class="right" @click="toPage({ path: '/home/aboutLink', index: '4' })">
            了解更多
          </p>
        </show>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    loginBackGround: (resolve) =>
      require(["@/components/common/loginBackGround.vue"], resolve),
  },
  data() {
    return {
      current: 1,
      styles1: {
        left: "-1000px",
        top: "15em",
      },
      shows: [
        { content: { flag: false, height: 200 } },
        { content: { flag: false, height: 1100 } },
        { content: { flag: false, height: 1700 } },
        { content: { flag: false, height: 2400 } },
        { content: { flag: false, height: 2760 } },
      ],
      swipers: [
        {
          class: "bg1",
          type: 1,
          name: "公用事业AIoT方案提供商",
          text1:
            "致力于推动AIoT技术在城市及公用事业的应用与落地，为水务、燃气、电力等市政与公用企事业单位，提供从智能设",
          text2: "备、大数据、AIoT应用开发到智能化运维的产品和服务。",
        },
        {
          class: "bg2",
          name: "构建公用物联网产业生态与商业闭环",
          text1:
            "通过连接设备厂商、城市市政与公用企业和终端用户，打通产业闭环，构建共享、共用、共赢的产业生态；并通过“大",
          text2: "数据+AIoT”赋能智慧城市，助力智能化、数字化转型升级。",
        },
        {
          class: "bg1",
          type: 1,
          name: "公用事业AIoT方案提供商",
          text1:
            "致力于推动AIoT技术在城市及公用事业的应用与落地，为水务、燃气、电力等市政与公用企事业单位，提供从智能设",
          text2: "备、大数据、AIoT应用开发到智能化运维的产品和服务。",
        },
        {
          class: "bg2",
          name: "构建公用物联网产业生态与商业闭环",
          text1:
            "通过连接设备厂商、城市市政与公用企业和终端用户，打通产业闭环，构建共享、共用、共赢的产业生态；并通过“大",
          text2: "数据+AIoT”赋能智慧城市，助力智能化、数字化转型升级。",
        },
      ],
    };
  },
  methods: {
    //页面跳转
    toPage(e) {
      sessionStorage.setItem("path", e.index);
      this.$router.push(e.path);
    },
    changeSwipper(e) {
      this.current = e == 0 || e == 2 ? 1 : "";
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .swipper {
    .bg1 {
      background-image: url("../../assets/images/bg_img/hf (2).jpg");
    }
    .bg2 {
      background-image: url("../../assets/images/bg_img/hf (3).jpg");
    }
    .bg1,
    .bg2 {
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }

    .title {
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 1em;
      padding-top: 6em;
    }
    .text {
      text-align: center;
    }
    .btns {
      width: 382px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 8em;
      p {
        width: 164px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
      }
      .more {
        border: 1px solid #fff;
      }
      .consult {
        background-color: #1f87e8;
      }
    }
  }
  .info_text {
    p {
      font-size: 1em;
    }
    .title {
      font-size: 1.8em;
      font-weight: bold;
      margin-bottom: 26px;
    }
  }
  .content1 {
    position: relative;
    height: 42em;
    img {
      top: 12em;
      max-width: 40em;
    }
    .btn {
      background-color: #01b5fd;
    }
  }

  .content2 {
    background: url("../../assets/images/bg_img/zhsw.jpg") no-repeat center;
    height: 42em;
    .move2 {
      width: 500px;

      color: #fff;
    }
    .btn {
      border: 1px solid #fff;
    }
  }

  .content3 {
    position: relative;
    height: 43.3em;
    background: url("../../assets/images/bg_img/zhrq.jpg") no-repeat;

    .btn {
      background-color: #01b5fd;
    }
  }

  .content4 {
    background: url("../../assets/images/bg_img/zhdl.jpg") no-repeat;
    height: 42em;
    .move4 {
      width: 500px;
      left: -1000px;
      top: 15em;
      color: #fff;
    }
    .btn {
      border: 1px solid #fff;
    }
  }

  .content5 {
    background-color: #2f4052;
    height: 9.3em;
    color: #fff;
    div p {
      top: 3.4em;
    }
    .left {
      width: 500px;
      font-size: 1.3em;
    }
    .right {
      width: 10rem;
      height: 3.2em;
      line-height: 3.2em;
      text-align: center;
      cursor: pointer;
      background-color: #017eb0;
      border-radius: 25px;
    }
  }
}

.btn_style {
  width: 10.7em;
  height: 2.7em;
  left: -1000px;
  font-size: 1em;
  line-height: 2.7em;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
<style>
.el-carousel__arrow {
  width: 70px !important;
  height: 70px !important;
  border-radius: 6px !important;
  font-size: 48px;
}
.el-carousel__arrowL:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.bgImage {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
</style>
